import "./App.css";
import { Header } from "./Header";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Main } from "./pages/main/Main";
import { WhoWeAre } from "./pages/whoweare/WhoWeAre";
import { Converter } from "./pages/converter/Converter";
import { Blog } from "./pages/blog/Communications";
import { ContactUs } from "./pages/contactus/ContactUs";
import { Footer } from "./Footer";
import { Awareness } from "./pages/awareness/awareness";
import { PangolinFacts } from "./pages/pangolinFacts/pangolinFacts";
import { ArtGallery } from "./pages/artGallery/artGallery";
import { Shop } from "./pages/shop/Shop";
import { CommunityCon } from "./pages/communitycon/communitycon";
import { WhichPango } from "./pages/whichPango/WhichPango";
import { PangolinFactsProvider } from "./context/PangolinFactsContext";
import { AwarenessProvider } from "./context/AwarenessContext";
import { ConverterProvider } from "./context/ConverterContext";
import { WhichPangolinProvider } from "./context/WhichPangolinContext";
import { CommunityConProvider } from "./context/CommunityConContext";
import { WhoWeAreProvider } from "./context/WhoWeAreContext";
import { MainProvider } from "./context/MainContext";
import { ContactProvider } from "./context/ContactContext";
import { FooterProvider } from "./context/FooterContext";
import { ShopProvider } from "./context/ShopContext";
import { Page404 } from "./pages/404/404";
import { Page404Provider } from "./context/404Context";
import { HeaderProvider } from "./context/HeaderContext";
import { BlogProvider } from "./context/CommunicationsContext";
import { Sitemap } from "./footer_pages/Sitemap";
import ScrollToTop from "./components/generic/ScrollToTop";
import { Careers } from "./pages/careers/Careers";
import { CareersProvider } from "./context/CareersContext";

const App: React.FC = () => {
  return (
    <>
      <Router>
        <HeaderProvider>
          <Header />
        </HeaderProvider>
        <ScrollToTop>
          <Routes>
            <Route
              path="/"
              element={
                <MainProvider>
                  <Main />
                </MainProvider>
              }
            />
            <Route
              path="/about"
              element={
                <WhoWeAreProvider>
                  <WhoWeAre />
                </WhoWeAreProvider>
              }
            />
            <Route
              path="/converter"
              element={
                <ConverterProvider>
                  <Converter />
                </ConverterProvider>
              }
            />
            <Route path="/communications" element={<BlogProvider><Blog /></BlogProvider>} />
          
            <Route
              path="/contact"
              element={
                <ContactProvider>
                  <ContactUs />
                </ContactProvider>
              }
            />
             <Route
              path="/careers"
              element={
                <CareersProvider>
                  <Careers />
                </CareersProvider>
              }
            />
            <Route
              path="/awareness"
              element={
                <AwarenessProvider>
                  <Awareness />
                </AwarenessProvider>
              }
            />
            <Route
              path="/facts"
              element={
                <PangolinFactsProvider>
                  <PangolinFacts />
                </PangolinFactsProvider>
              }
            /> 
            <Route path="/gallery" element={<ArtGallery />} />
            <Route
              path="/community"
              element={
                <CommunityConProvider>
                  <CommunityCon />
                </CommunityConProvider>
              }
            />
            <Route
              path="/quiz"
              element={
                <WhichPangolinProvider>
                  <WhichPango />
                </WhichPangolinProvider>
              }
            />
            <Route 
              path="/shop"
              element={
                <ShopProvider>
                  <Shop />
                </ShopProvider>
              }
            />
            <Route 
              path="*"
              element={
                <Page404Provider>
                  <Page404/>
                </Page404Provider>
              }
            />
            <Route 
              path="/sitemap"
              element={
                <HeaderProvider>
                  <Sitemap/>
                </HeaderProvider>
              }
            />
          </Routes>
          <FooterProvider>
            <Footer />
          </FooterProvider>
        </ScrollToTop>
      </Router>
    </>
  );
}

export default App;
